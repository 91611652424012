  .categoriesWrap {
    margin: 20px 0;
    padding: 0px 10px;
    z-index: 9;
    overflow-y: scroll;
    height: 100%;

    .checkboxWrap {
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 5px 0;
    }
  }


  .uploadWrapper {
    aspect-ratio: 2 / 1;
    width: 100%;
    border-radius: 8px;
  }

  .imageUploadField {
    height: 100%;
    width: 100%;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid #e9e9e9;
  }

  .uploadedImage {
    object-fit: contain;
  }


  .header {
    color: #161616;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 15px;
    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: #FFF;
  }

  .subHeader {
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    margin: 15px 0 10px;
  }


  .addItemsButton {
    margin: 20px 0;
    color: #161616;
  }

  .changeImageButton {
    width: 150px;
    font-size: 14px;
    padding: 5px;
  }

  .category {
    border-bottom: 1px solid #E8E8E8;
    border-top: 1px solid #E8E8E8;
    padding: 10px 0;
    margin-top: -1px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .categoryName {
      color: #161616;
      font-family: Inter;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .categoriesListWrap {
    margin: 40px 0;
  }

  .noCategories {
    border-radius: 8px;
    background: #F4F4F4;
    height: 150px;
    width: 100%;
    margin: 50px 0;
    color: #838383;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 150px;
  }

  .imageWrap {
    width: 100%;

    .image {
      width: 100%;
    }
  }

  .categoryItemWrap {
    &.editMode {
      padding: 10px 5px;
      background-color: #F8F8F8;;
    }
  }

  .aspectRatioDisclaimer {
    font-size: 12px;
    color: #838383;
  }