.inputWrap {
    border: 1px solid #8F9AB7;
    padding: 10px;
    width: 100%;

    .inputHandler {
        text-align: center;

        img {
            max-height: 100px;
            width: auto;
            margin: 10px 0;
        }
    }
}

.fileList {
    gap: 5px;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    width: 100%;

    .mediaContainer {
        position: relative;
        width: calc(50% - 2.5px);
        aspect-ratio: 1;
        overflow: hidden;
        border: 1px solid #8F9AB7;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .trashIcon {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 100%;
        z-index: 2;
        height: 24px;
        width: 24px;
    }

    .image {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    .video {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;

    }
}
