@use 'src/styles/_colors';

.SingleCommercial {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 6px;
  border: 1px solid colors.$grey_border;
  padding: 32px;
  gap: 16px;
  box-sizing: border-box;

  .Image {
    border: 1px solid colors.$grey_border;
    border-radius: 6px;
    width: 100%;
    max-width: 350px;
  }

  .Details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

    .OptionsWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .Option {
      //width: 100%;
      height: 32px;
      border-radius: 6px;
      border: 1px solid colors.$grey_border;
      outline: none;
      padding-left: 8px;
    }

    .Option:focus, .Option:hover {
      border: 1px solid colors.$primary_blue;
    }

    .Submit {
      width: 100%;
      color: white;
      background-color: colors.$primary_blue;
      border: 1px solid colors.$primary_blue;
      border-radius: 3px;
      padding: 8px;
      font-size: 18px;
      font-family: 'Lexend', sans-serif;
      flex: 50;
    }

    .SubmitDisabled {
      background-color: colors.$grey_border;
      border: 1px solid colors.$grey_border;
    }
    .ValidationErrorMessage {
      color: red;
      font-size: 12px;
    }
  }

  .ActionsWrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 100%;

    button {
      flex: 1;
    }

    .IconStyle {
      stroke: white;
    }
  }
}